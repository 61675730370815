.__react_modal_image__modal_container,
.__react_modal_image__header {
    background-color: #0437ff35!important;
    backdrop-filter: blur(3px)
}

.__react_modal_image__header {
    background-color: #0437ff35!important;
    box-shadow: 0px 0px 13px 13px #0437ff50;
}
